import React, { Component } from 'react';
import { Helmet } from 'react-helmet';

import ReactHtmlParser from 'react-html-parser';

class FAQList extends Component {

    state = {
        faq: [{
            ques: 'Where do you guys operate?',
            ans: 'We are currently operational in Delhi, Gurgaon and Noida.'
        }, {
            ques: 'Is there a contract? What are the terms?',
            ans: `Yes, there is a minimum renting period of 3 months. The period can be extended further by contacting our customer care team 2 weeks prior to the end of the contract. We don't charge anything for contyract extension.`
        },
        {
            ques: `Can I purchase the furniture?`,
            ans: `No. This is a rental platform and hence, you can't purchase the products.`
        }, {
            ques: `What documents are required to get into the contract?`,
            ans: `<ul>
                        <li>Photo ID proof: Passport / PAN Card / Driving License / Aadhar Card</li>
                        <li>Address proof: House rental agreement / Address proof on compoany letterhead / Govt. issued proof / Bank statement</li>
                        <li>NOC from the landlord and rental agreement if you are living at a place whoose address is different from your permanent address</li>
                    </ul>`
        }, {
            ques: `What amount has to be paid while placing the order?`,
            ans: `You need to pay one month’s rent in advance and security deposit. You can pay the entire amount through online payment mode or use NACH facility. The rent needs to be placed by 7 th of every month. Late payment will lead to extra charges. Voorent has the right to cancel the subscription and pickup it’s products if the amount is not paid within 30 days of due date.`
        }, {
            ques: `What is the delivery and installation process?`,
            ans: `The products will be delivered by Voorent as per the delivery timeline setup. You would not be charged for delivery and installation of products. However, if delivery has to be made at a location without a lift, there will be a labor fee charged which will be collected in cash at the time of delivery; the amount will vary on the basis of number of products and floor level.<br/>
                    Even if you relocate within the same city during the tenure of contract, Voorent will relocate the rented products free of cost.`
        }, {
            ques: `How much days will be taken to deliver the products?`,
            ans: `The products will be delivered within 3 days after you have placed an order.`
        }, {
            ques: `How can the contract be extended or terminated?`,
            ans: `In case there is an early termination of the order as placed by the User, the User can request for early closure by informing the <b>Company</b> 2 weeks prior to the selected closure date. In such a case where the <b>User</b> wishes to terminate thr order earlier than the prescribed time, the <b>Company</b> shall not refund any security deposit to the User at any point owing to early termination of the agreement.<b> (Security Deposit will be forfeited)</b>.<br/>`
        }, {
            ques: `What if products get damaged?`,
            ans: `The minor damages will be ignored. In case you render the product unusable, you would have to pay the amount decided by Voorent based on the market value of the product(s) minus the total rent paid by you.`
        }]
    }

    render() {
        return (
            <React.Fragment>
                <Helmet>
                    <meta name="description" content="We brings you a wide range of home appliances in rent in gurgaon with quick delivery with 2-3 days of ordering." />
                    <meta name="keywords" content="Appliances on rent in gurgaon" />
                    <meta name="title" content="Rent Necessary home appliance in gurgaon l Delhi" />
                </Helmet>
                {
                    this.state.faq.map((item,fIndex) => (
                        <div key={fIndex} className="col-12 mt-3">
                            <span className="d-block font-weight-bold">{item.ques}</span>
                            <span className="d-block text-black-50"> { ReactHtmlParser(item.ans.toString()) }</span>
                        </div>
                    ))
                }


            </React.Fragment>
        );
    }
}

export default FAQList;