import React, { Component } from 'react';
import MdCheckmarkCircleOutline from 'react-ionicons/lib/MdCheckmarkCircleOutline';

class OrderInfo extends Component {
    render() {
        return (
            <div className="row mb-4 ml-1 mr-1 mt-4">
                <div className="col-12">
                    {
                        this.props.isMedical != true && (
                    <div>        
                    <span className="d-block mt-2"><MdCheckmarkCircleOutline />
                        You will pay one month's rent and a refundable security deposit equal to two month’s rent to confirm your booking right now.
                    </span>
                    <span className="d-block mt-2"><MdCheckmarkCircleOutline />
                        You’ll be required to upload address and ID proof documents after payment. Your items will be shipped once the documents are verfied.
                    </span>
                    </div>)
                    }
                    <span className="d-block mt-2"><MdCheckmarkCircleOutline />
                        Your billing cycle will begin from the date of your delivery.
                    </span>
                    <span className="d-block mt-2"><MdCheckmarkCircleOutline />
                        Minimal rental amount for order acceptance in Delhi and Manesar.(Rs. 1000)
                    </span>
                    <span className="d-block mt-2"><MdCheckmarkCircleOutline />
                        Minimal rental amount for order acceptance in Gurugram.(Rs. 500)
                    </span>
                    {
                        this.props.isMedical==true && (
                            <div>
                                <span className="d-block mt-2"><MdCheckmarkCircleOutline />
                                    Rates are without gst ( 18% gst will be applied).	
                                </span>
                                <span className="d-block mt-2"><MdCheckmarkCircleOutline />
                                    Delivery charges will be according to the distance.
                                </span>
                                <span className="d-block mt-2"><MdCheckmarkCircleOutline />
                                    Post delivery check will be collected at the time of delivery equivalent to cost of equipment.
                                </span>
                            </div>
                        )
                    }
                </div>
            </div>
        );
    }
}

export default OrderInfo;